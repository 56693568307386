import React, { useEffect, useState } from "react";
import { Drawer, Tabs, Card, Tag, Button, message } from "antd";

import { Tooltip, Space } from "antd";

import { InfoCircleOutlined } from "@ant-design/icons";

import Request from "../../Requests";

import {
  TableDateHHMMSS,
  confirmModal,
  TableText,
  ResellerInfoDrawer,
} from "../../components";

import DevicePlaylists from "./components/DevicePlaylists";
import DeviceStateChanges from "./components/DeviceStateChanges";
import DeviceDrawer from "./components/DeviceDrawer";

export default function DeviceInfoDrawer({ open, onClose, deviceKey }) {
  const [profile, setProfile] = useState(null);
  const [provider, setProvider] = useState(null);
  const [device, setDevice] = useState(null);
  const [activeKey, setActiveKey] = useState("playlists");
  const [isDeviceDrawer, setIsDeviceDrawer] = useState(false);

  const [openResellerInfo, setOpenResellerInfo] = useState({
    open: false,
    resellerId: null,
  });
  useEffect(() => {
    if (!open) {
      setProfile(null);
      setProvider(null);
      setDevice(null);
    }
  }, [open]);

  useEffect(() => {
    getProfile();

    if (deviceKey) {
      getDevice();
    }
  }, [deviceKey]);

  const onChange = (key) => {
    setActiveKey(key);
  };

  const getProfile = () => {
    Request.profile()
      .then((data) => {
        setProfile(data);
      })
      .catch((err) => {});
  };

  function getProvider(filterVal) {
    const query = {
      filter: { id: filterVal },
    };
    Request.provider
      .get({ query: JSON.stringify(query) })
      .then((data) => {
        if (data) {
          setProvider(data?.rows[0]);
        }
      })
      .catch(({ message }) => {});
  }

  function getDevice() {
    const query = {
      filter: { device_key: deviceKey },
    };
    Request.all_devices({ query: JSON.stringify(query) })
      .then((data) => {
        if (data) {
          setDevice(data?.rows[0]);
          if (data?.rows[0]?.provider_id) {
            getProvider(data?.rows[0]?.provider_id);
          }
        }
      })
      .catch(({ message }) => {});
  }

  const items = [
    device?.device_key && {
      key: "playlists",
      label: "Playlist changes",
      children: <DevicePlaylists deviceKey={device?.device_key} />,
    },
    device?.device_key && {
      key: "status changes",
      label: "Status changes",
      children: <DeviceStateChanges deviceKey={device?.device_key} />,
    },
  ];

  const onDeactive = () => {
    confirmModal({
      title: "Do you want to deactivate this device",
      action: () => {
        Request.device_deactivate({ id: device?.id })
          .then((res) => {
            message.success(res);
            getDevice();
          })
          .catch((err) => {
            message.error(err);
          });
      },
    });
  };

  const checkPlaylistForError = (playlistStatus) => {
    switch (playlistStatus) {
      case "wrong":
        return "Please check the playlist as it is incorrectly filled in.";
      case "expired":
        return "Please check this playlist as its expiration date has expired.";
      default:
    }
  };
  return (
    <>
      <Drawer
        title="Device Info"
        open={open}
        onClose={onClose}
        size="large"
        className="info-drawer"
      >
        <Card style={{ margin: "17px 0" }}>
          <div style={{ display: "flex", gap: "110px" }}>
            <div>
              <p>
                <strong>Device key: </strong>
                {device?.device_key || "N/A"}
              </p>
              <p>
                <strong>Activated by: </strong>
                {device?.activated_by || "N/A"}
              </p>
              <p>
                <strong>Device OS: </strong>
                {device?.device_os || "N/A"}
              </p>
              <p>
                <strong>Registered on: </strong>
                {device?.app_name || "N/A"}
              </p>
              <p>
                <strong>Status: </strong>
                {device?.is_active ? (
                  <Tag color="green">Active</Tag>
                ) : !device?.is_active && device?.is_trial ? (
                  <Tag color="gold">Trial</Tag>
                ) : (
                  <Tag color="red">Deactive</Tag>
                )}
              </p>
              {/* actions */}

              <p>
                <strong>App version: </strong>
                {device && device.app_version != null
                  ? device.app_version
                  : "N/A"}
              </p>
              <p>
                <strong>Model: </strong>
                {device && device.model != null ? device.model : "N/A"}
              </p>
              <p>
                <strong>Browser version: </strong>
                {device && device.browser_version != null
                  ? device.browser_version
                  : "N/A"}
              </p>
            </div>
            <div>
              <p>
                <strong>Provider: </strong>
                {provider ? (
                  <Button
                    type="ghost"
                    onClick={() =>
                      setOpenResellerInfo({
                        open: true,
                        resellerId: provider?.id,
                      })
                    }
                  >
                    {provider?.brand_name + " " + provider?.email}
                  </Button>
                ) : (
                  "N/A"
                )}
              </p>
              <p>
                <strong>Country: </strong>
                {`${device?.country || "N/A"} (IP: ${
                  device?.ip_address || "N/A"
                })` || "N/A"}
              </p>
              <p>
                <strong>Registered date: </strong>
                {device?.createdAt ? (
                  <TableDateHHMMSS date={device?.createdAt} />
                ) : (
                  "N/A"
                )}
              </p>
              <p>
                <strong>Updated date: </strong>
                {device?.updatedAt ? (
                  <TableDateHHMMSS date={device?.updatedAt} />
                ) : (
                  "N/A"
                )}
              </p>

              <p>
                <strong>Free trial expired date: </strong>
                {device?.free_trial_expired ? (
                  <TableDateHHMMSS date={device?.free_trial_expired} />
                ) : (
                  "N/A"
                )}
              </p>

              <p>
                <strong>Expired date: </strong>
                {device?.expired_date ? (
                  <TableDateHHMMSS date={device?.expired_date} />
                ) : (
                  "N/A"
                )}
              </p>

              <p>
                <strong>Playlist: </strong>
                {device?.playlist
                  ? (() => {
                      const playlistStatus =
                        device?.playlist_status?.toLowerCase();
                      if (!device?.playlist) {
                        return "N/A";
                      } else if (
                        playlistStatus === "wrong" ||
                        playlistStatus === "expired"
                      ) {
                        return (
                          <>
                            <Tooltip
                              title={checkPlaylistForError(playlistStatus)}
                            >
                              <Button
                                icon={
                                  <InfoCircleOutlined
                                    style={{ color: "#cf1222" }}
                                  />
                                }
                                type="link"
                              />
                            </Tooltip>
                            <Space>
                              <TableText
                                text={device?.playlist}
                                style={{ color: "#cf1222" }}
                              />
                            </Space>
                          </>
                        );
                      } else {
                        return <Space>{device?.playlist}</Space>;
                      }
                    })()
                  : "N/A"}
              </p>

              <div style={{ display: "flex", alignItems: "baseline", gap: 8 }}>
                {(profile?.permission_level ||
                  profile?.permission?.deactivate_device) && (
                  <Button type="primary" danger ghost onClick={onDeactive}>
                    {" "}
                    Deactive{" "}
                  </Button>
                )}
                {(profile?.permission_level ||
                  profile?.permission?.edit_playlist) && (
                  <Button
                    type="primary"
                    ghost
                    onClick={() => setIsDeviceDrawer(true)}
                  >
                    {" "}
                    Edit{" "}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Card>
        <Tabs defaultActiveKey={activeKey} items={items} onChange={onChange} />
        <DeviceDrawer
          open={isDeviceDrawer}
          onClose={() => setIsDeviceDrawer(false)}
          provider={provider}
          selected={device}
          getDevice={getDevice}
        />
      </Drawer>
      <ResellerInfoDrawer
        resellerId={openResellerInfo?.resellerId}
        open={openResellerInfo.open}
        onClose={() => setOpenResellerInfo({ open: false, resellerId: null })}
      />
    </>
  );
}
