import { useEffect, useState } from "react";
import Request from "../../Requests";
import { Drawer, Form, Button, notification, Input, Switch } from "antd";
import { createFieldsValue } from "../../Utils";
export default function PartnerPackagesDrawer({
  visible,
  onClose,
  selectedRow,
  isGetOptions,
  getOptionsPartnerPackages,
}) {
  const fields = {
    name: {
      type: "STRING",
      rules: [{ required: true, message: "Name is required!" }],
    },

    price: {
      type: "INTEGER",
      rules: [{ required: true, message: "Price is required!" }],
    },

    device_count: {
      type: "INTEGER",
      rules: [{ required: true, message: "Devices count is required!" }],
    },

    unlimited: {
      type: "BOOLEAN",
      rules: [{ required: false, message: "" }],
    },
  };
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  let platforms_list;

  if (selectedRow) {
    platforms_list = selectedRow?.platforms?.map((item) => {
      return item.device_os;
    });
  }

  const onFinish = (data) => {
    let method = "post";
    let values = {};

    setLoading(true);

    for (const key in data) {
      if (data[key] !== "") values[key] = data[key];
    }

    if (selectedRow) {
      method = "put";
      values.id = selectedRow.id;
    }

    Request.partner_packages[method](values)
      .then(() => {
        setLoading(false);
        hideDrawer(true);
        if (isGetOptions) {
          getOptionsPartnerPackages("set field");
        }
      })
      .catch(({ message }) => {
        setLoading(false);
        notification.error({ message });
      });
  };

  const hideDrawer = (changed) => {
    onClose(changed);
    form.resetFields();
  };

  useEffect(() => {
    if (visible) {
      if (selectedRow) {
        form.setFields([{ name: "platforms", value: platforms_list }]);
      }
      delete fields.platforms;
      form.setFieldsValue(createFieldsValue(selectedRow, fields));
    } else {
      form.resetFields();
    }
  }, [visible]);

  return (
    <Drawer
      title={selectedRow ? "Update Partner Package" : "Add New Partner Package"}
      placement="right"
      onClose={() => hideDrawer(false)}
      open={visible}
      width={600}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        itialValues={{ platforms: selectedRow?.platforms }}
      >
        <Form.Item label="Name" name="name" rules={fields.name.rules}>
          <Input allowClear placeholder="Name" />
        </Form.Item>

        <Form.Item
          label="Devices count"
          name="device_count"
          rules={fields.device_count.rules}
        >
          <Input allowClear placeholder="Devices count" />
        </Form.Item>

        <Form.Item label="Price" name="price" rules={fields.price.rules}>
          <Input allowClear placeholder="Price" />
        </Form.Item>

        <Form.Item
          name="unlimited"
          label="Unlimited"
          rules={fields.unlimited.rules}
          valuePropName="checked"
        >
          <Switch rules={fields.unlimited.rules} />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginRight: 10 }}
            loading={loading}
          >
            {selectedRow ? "Update" : "Add"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
