import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/plots";
import moment from "moment";
import { Card, Empty, DatePicker } from "antd";
import Request from "../../../Requests";

export default function DevicesRegistration() {
  const [dataList, setDataList] = useState([]);
  const defaultDate = `${new Date().getFullYear()}-01-01`;
  const [selectedDate, setSelectedDate] = useState(defaultDate);

  useEffect(() => {
    getData();
  }, [selectedDate]);

  const getData = () => {
    const query = {
      date: selectedDate,
    };
    Request.resller_devices
      .yearly_chart(query)
      .then((data) => {
        setDataList(data);
      })
      .catch(({ err }) => {
        // console.log(err);
      });
  };

  const onChangeDate = (date, dateString) => {
    if (date) {
      const selectedYear = dateString;

      const formattedString = `${selectedYear}-01-01`;

      setSelectedDate(formattedString);
    } else {
      setSelectedDate(new Date());
      return selectedDate;
    }
  };

  const config = {
    data: dataList && dataList,
    xField: "type",
    yField: "sales",
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: "类别",
      },
      sales: {
        alias: "Devices",
      },
    },
    style: {
      minHeight: 250,
    },
  };
  return (
    <Card
      title="Device Registration by Year"
      extra={
        <DatePicker
          onChange={onChangeDate}
          picker={"year"}
          value={moment(selectedDate)}
        />
      }
    >
      {dataList.length > 0 ? <Column {...config} /> : <Empty />}
    </Card>
  );
}
