import { Route, Routes } from "react-router-dom";

import {
  WelcomePage,
  LoginPage,
  DashboardPage,
  BillingHistoryPage,
  NewsPage,
  NotificationsPage,
  ResellerPage,
  CreditTransactionPage,
  PlatformsPage,
  AlertsPage,
  PartnerPackagesPage,
  AllDevices,
  ErrorLogs,
  SettingsPage,
  AdminsPage,
  BannerAd,
} from "./pages/index";

import Root from "./layout/Root";
import NotFound from "./layout/NotFound";

import "antd/dist/antd.css";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<WelcomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/dashboard" element={<Root />}>
          <Route path="/dashboard/analytics" element={<DashboardPage />} />
          <Route
            path="/dashboard/partner-packages"
            element={<PartnerPackagesPage />}
          />
          <Route path="/dashboard/alerts" element={<AlertsPage />} />
          <Route path="/dashboard/resellers" element={<ResellerPage />} />
          <Route
            path="/dashboard/credit-transaction"
            element={<CreditTransactionPage />}
          />

          <Route
            path="/dashboard/billing-history"
            element={<BillingHistoryPage />}
          />
          <Route path="/dashboard/news" element={<NewsPage />} />
          <Route
            path="/dashboard/notifications"
            element={<NotificationsPage />}
          />
          <Route path="/dashboard/platforms" element={<PlatformsPage />} />
          <Route path="/dashboard/devices" element={<AllDevices />} />
          <Route path="/dashboard/player-error-logs" element={<ErrorLogs />} />
          <Route path="/dashboard/settings" element={<SettingsPage />} />
          <Route path="/dashboard/admins" element={<AdminsPage />} />
          <Route path="/dashboard/banner-ad" element={<BannerAd />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
