import React, { useState, useEffect } from "react";
import { Bar } from "@ant-design/plots";
import { Card, Empty } from "antd";
import Request from "../../../Requests";

export default function ByPlatformsDevices() {
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    Request.resller_devices
      .by_platforms({})
      .then((data) => {
        setDataList(data);
      })
      .catch(({ err }) => {
        // console.log(err);
      });
  };
  const config = {
    data: dataList && dataList,
    xField: "sales",
    yField: "type",
    seriesField: "type",
    label: false,
    style: {
      minHeight: 250,
    },
  };
  return (
    <Card title="Devices by Platforms">
      {dataList.length > 0 ? <Bar {...config} /> : <Empty />}
    </Card>
  );
}
