import { useState, useEffect, useRef } from "react";

import { Popover, Table, notification, Result } from "antd";

import {
  TableAddButton,
  TableActions,
  TableText,
  TableImage,
  TitlePage,
  TableDateHHMMSS,
  RangePickerFilterDropdown,
} from "../../components";

import Request from "../../Requests";

import NewsDrawer from "./NewsDrawer";

import { generateQuery } from "../../Utils";

import useColumns from "../../hooks/useColumns";
import useHistorySettings from "../../hooks/useHistorySettings";

import { getColumnSearchProps } from "../../configs/config";

export default function NewsPage() {
  const fieldsType = {
    id: "INTEGER",
    title: "STRING",
    description: "TEXT",
    image: "TEXT",
    createdAt: "DATE",
    updatedAt: "DATE",
  };

  const defaultTableState = {
    filteredInfo: {},
    sortedInfo: {},
    page: 1,
    limit: 20,
  };

  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const searchInputRef = useRef(null);

  const [data, setData] = useState([]);

  const [selected, setSelected] = useState(null);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [loading, setLoading] = useState(false);

  const [tableState, setTableState] = useHistorySettings(defaultTableState);

  const [total, setTotal] = useState(0);

  const [forbidden, setForbidden] = useState(false);

  const findText = (record, type, isoCode) => {
    const txt = record?.news_translations?.find(
      (item) => item.iso_code === isoCode
    );

    return txt?.[type] || "N/A";
  };

  const [columns, setColumns] = useState([
    {
      title: "#",
      fixed: "left",
      key: "row__index",
      render: (text, record, index) => record.index,
    },

    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (text, record, index) => <TableImage src={text} />,
    },

    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record, index) => (
        <TableText
          style={{ width: 290 }}
          text={findText(record, "title", record?.isoCode)}
        />
      ),
      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
      render: (text, record, index) => {
        return (
          <Popover title={findText(record, "description", record?.isoCode)}>
            <p
              style={{
                width: 470,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {findText(record, "description", record?.isoCode)}
            </p>
          </Popover>
        );
      },
      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "Updated date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
      sorter: true,
      filterDropdown: RangePickerFilterDropdown,
    },
    {
      title: "Created date",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
      sorter: true,
      filterDropdown: RangePickerFilterDropdown,
    },
    {
      title: "Action",
      key: "row__actions",
      fixed: "right",
      width: 100,
      render: (text, record, index) => (
        <TableActions
          onEdit={showDrawer}
          onDelete={deleteData}
          record={record}
        />
      ),
    },
  ]);

  const [onChangeColumns] = useColumns(columns, setColumns, "news");

  const getData = () => {
    let query = generateQuery(tableState, fieldsType);

    setLoading(true);

    Request.news
      .get(query)
      .then((data) => {
        if (!data.rows.length && tableState.page > 1) {
          return setTableState({ ...tableState, page: tableState.page - 1 });
        }

        setLoading(false);
        setData(
          data.rows.map((item, index) => ({
            index: (tableState.page - 1) * tableState.limit + index + 1,
            ...item,
          }))
        );
        setTotal(data.total);
        setSelectedRowKeys([]);
      })
      .catch(({ message }) => {
        if (message == "Forbidden") {
          setForbidden(true);
        }
        setLoading(false);
      });
  };

  const deleteData = ({ id }) => {
    Request.news
      .delete({ id })
      .then(() => {
        setTableState((prev) => {
          return { ...prev };
        });
      })
      .catch(({ message }) => {
        notification.error({ message });
      });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const showDrawer = (selected = null) => {
    setSelected(selected);
    setVisibleDrawer(true);
  };

  const hideDrawer = (changed) => {
    setVisibleDrawer(false);
    if (changed) {
      setTableState((prev) => {
        return { ...prev };
      });
    }
  };

  const tableOnChange = (pagination, filters, sorter) => {
    let { order, field } = sorter;

    setTableState({
      filteredInfo: filters,
      sortedInfo: { order, field },
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  useEffect(() => {
    let _columns = [...columns];
    let { filteredInfo, sortedInfo } = tableState;

    _columns.map((column) => {
      column.filteredValue = filteredInfo[column.key] || null;
      column.sortOrder = sortedInfo.field === column.key && sortedInfo.order;
    });

    setColumns(_columns);

    const timeout = setTimeout(() => {
      getData();
    }, 500);
    return () => clearTimeout(timeout);
  }, [tableState]);

  useEffect(() => {
    if (!visibleDrawer) {
      setSelected(null);
    }
  }, [visibleDrawer]);

  return (
    <div>
      <TitlePage title="News" />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexWrap: "wrap",
          gap: 8,
          marginBottom: 20,
        }}
      >
        {!forbidden && (
          <TableAddButton disabled={forbidden} onClick={() => showDrawer()} />
        )}
      </div>
      {forbidden ? (
        <Result
          status="500"
          title="500"
          subTitle="We have a problem with News service, please check it (:"
        />
      ) : (
        <>
          <Table
            loading={loading}
            rowKey="id"
            columns={columns.filter((column) => column.visible)}
            dataSource={data}
            scroll={{
              x: "max-content",
              y: null,
            }}
            onChange={tableOnChange}
            size="small"
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
            }}
            pagination={{
              position: ["bottomCenter"],
              current: tableState.page,
              total: total,
              pageSize: tableState.limit,
              showSizeChanger: true,
            }}
            bordered
          />
          <NewsDrawer
            visible={visibleDrawer}
            onClose={hideDrawer}
            selectedRow={selected}
          />
        </>
      )}
    </div>
  );
}
