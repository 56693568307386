import { Drawer, Form, InputNumber, Button, notification } from "antd";
import React, { useEffect, useState } from "react";
import Request from "../../../Requests";

export default function CreditDrawer({
  open,
  onClose,
  providerId,
  getProviderInfo,
  currentCredit,
}) {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (!open?.show) {
      form.resetFields();
    }
  }, [open?.show]);
  const addCredit = (_, val) => {
    if (!val) {
      setDisabled(false);
      return Promise.reject("");
    }
    return Promise.resolve();
  };
  const takeCredit = (_, val) => {
    if (val && Number(currentCredit) < Number(val)) {
      form.setFields([
        {
          name: "credit",
          errors: ["Taked credit is greater than the provider have credit"],
        },
      ]);
      setDisabled(true);
    } else {
      setDisabled(false);

      return Promise.resolve();
    }
  };

  const onFinish = (values) => {
    setLoading(true);

    const body = {};

    if (values.credit && providerId) {
      body.id = Number(providerId);
      body.credit =
        open.type == "add"
          ? Number(Math.abs(values.credit))
          : Number(-values.credit);

      Request.credit_provider(body)
        .then((res) => {
          setLoading(false);
          getProviderInfo();
          onClose();
          notification.success({ description: res });
        })
        .catch((err) => {
          setLoading(false);
          notification.error({ description: err?.message });
        });
    }
  };

  return (
    <Drawer
      title={open.type == "add" ? "Add credit" : "Take credit"}
      open={open.show}
      onClose={onClose}
    >
      <Form name="credit" form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Credit"
          name="credit"
          rules={[
            {
              required: true,
              message: "Please input your credit!",
            },
            {
              validator: open.type == "add" ? addCredit : takeCredit,
            },
          ]}
        >
          <InputNumber
            type="number"
            controls={false}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item style={{ marginTop: "20px" }}>
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
            disabled={disabled}
          >
            {open.type == "add" ? "Add" : "Take"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
